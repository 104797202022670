var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ics-page-inner",
    { attrs: { title: _vm.titleName } },
    [
      _c("template", { slot: "btn-inner" }, [
        _c(
          "div",
          { staticClass: "btn-inner" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.download("OneKey")
                  }
                }
              },
              [_vm._v(" 一键下载 ")]
            )
          ],
          1
        )
      ]),
      [
        _c(
          "el-col",
          { attrs: { span: 24 } },
          [
            _c("span", [_vm._v("企业资料/企业资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("customerFirm")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.customerDetail.fileFirmDto
          ? _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("file-list", {
                  attrs: {
                    "list-type": "picture-card",
                    files: _vm.customerDetail.fileFirmDto.firmList,
                    "handle-preview": function(val) {
                      return _vm.previewFile(val, "firm")
                    },
                    props: { name: "typeName", src: "url" }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("授信资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("creditCustomer")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/合同签署资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCustomerCon")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.creditDto.contractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "concat")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/附件资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCustomerEnc")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.creditDto.enclosureFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "credit")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/基础合同资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCustomerBasic")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files:
                              _vm.customerDetail.creditDto.basicContractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "other")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/发票资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCustomerInvoice")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.creditDto.invoiceFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "invoice")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("用信资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("useCustomer")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/合同签署资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerContract")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.contractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "concat")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/附件资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerEnclosure")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.enclosureFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/应收账款资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerRe")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.reFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "other")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/应收发票资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerReInvoice")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.reInvoiceFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "invoice")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/放款凭证")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerVoucher")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.loanFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/收款凭证")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCustomerCollection")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.useDto.collectionFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("付款资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("repaymentCustomer")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("付款资料/付款凭证资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("repaymentCustomerCo")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.repaymentDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.repaymentDto.pay,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "repay")
                            },
                            props: { name: "typeCode", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("付款资料/收款凭证资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("repaymentCustomerPay")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.customerDetail.repaymentDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.customerDetail.repaymentDto.co,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "repay")
                            },
                            props: { name: "typeCode", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }