var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ics-page-inner",
    { attrs: { title: _vm.titleName } },
    [
      _c("template", { slot: "btn-inner" }, [
        _c(
          "div",
          { staticClass: "btn-inner" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.download("OneKey")
                  }
                }
              },
              [_vm._v(" 一键下载 ")]
            )
          ],
          1
        )
      ]),
      [
        _c(
          "el-col",
          { attrs: { span: 24 } },
          [
            _c("span", [_vm._v("企业资料/企业资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("enterprise")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.coreDetail.fileFirmDto
          ? _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("file-list", {
                  attrs: {
                    "list-type": "picture-card",
                    files: _vm.coreDetail.fileFirmDto.firmList,
                    "handle-preview": function(val) {
                      return _vm.previewFile(val, "firm")
                    },
                    props: { name: "typeName", src: "url" }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("申请资料/申请额度附件资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("coreQuota")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.coreDetail.fileFirmDto
          ? _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("file-list", {
                  attrs: {
                    "list-type": "picture-card",
                    files: _vm.coreDetail.fileFirmDto.quotaList,
                    "handle-preview": function(val) {
                      return _vm.previewFile(val, "other")
                    },
                    props: { name: "typeName", src: "url" }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("授信资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("creditCore")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/合同签署资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCoreCon")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.creditDto.contractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "concat")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/附件资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("creditCoreEnc")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.creditDto.enclosureFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "credit")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/基础合同资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("coreBasic")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.creditDto.basicContractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "other")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("授信资料/发票资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("coreInvoice")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.creditDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.creditDto.invoiceFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "invoice")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("用信资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("useCore")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/合同签署资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCoreContract")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.contractFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "concat")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/附件资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCoreEnclosure")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.enclosureFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/应收账款资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("coreRe")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.reFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "other")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/应收发票资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCoreReInvoice")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.reInvoiceFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "invoice")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/放款凭证")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCoreCollection")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.loanFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("用信资料/收款凭证")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("useCoreLoan")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.useDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.useDto.collectionFile,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "use")
                            },
                            props: { name: "typeName", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-col",
          { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
          [
            _c("span", [_vm._v("付款资料")]),
            _c(
              "el-divider",
              { attrs: { "content-position": "right" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.download("repaymentReCo")
                      }
                    }
                  },
                  [_vm._v(" 下载 ")]
                )
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("付款资料/付款凭证资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("repaymentCoreCo")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.repaymentDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.repaymentDto.pay,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "repay")
                            },
                            props: { name: "typeCode", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { staticStyle: { "margin-top": "5px" }, attrs: { span: 24 } },
                  [
                    _c("span", [_vm._v("付款资料/收款凭证资料")]),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.download("repaymentCorePay")
                              }
                            }
                          },
                          [_vm._v(" 下载 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.coreDetail.repaymentDto
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("file-list", {
                          attrs: {
                            "list-type": "picture-card",
                            files: _vm.coreDetail.repaymentDto.co,
                            "handle-preview": function(val) {
                              return _vm.previewFile(val, "repay")
                            },
                            props: { name: "typeCode", src: "url" }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      _c(
        "div",
        { staticClass: "partition-area" },
        _vm._l(_vm.coreDetail.customerDto, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: item.fileFirmDto.name } },
                [
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("span", [_vm._v("企业资料/企业资料")]),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "customerFirm"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 下载 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    item.fileFirmDto
                      ? _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("file-list", {
                              attrs: {
                                "list-type": "picture-card",
                                files: item.fileFirmDto.firmList,
                                "handle-preview": function(val) {
                                  return _vm.previewFile(val, "firm")
                                },
                                props: { name: "typeName", src: "url" }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { span: 24 }
                      },
                      [
                        _c("span", [_vm._v("资产资料")]),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "assetsCustomer"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 下载 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c("span", [_vm._v("资产资料/基础合同资料")]),
                            _c(
                              "el-divider",
                              { attrs: { "content-position": "right" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.customerDownload(
                                          item.fileFirmDto.firmId,
                                          "assetsCustomerBasic"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 下载 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        item.assetsDto
                          ? _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("file-list", {
                                  attrs: {
                                    "list-type": "picture-card",
                                    files: item.assetsDto.contractFile,
                                    "handle-preview": function(val) {
                                      return _vm.previewFile(val, "other")
                                    },
                                    props: { name: "typeName", src: "url" }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c("span", [_vm._v("资产资料/应付账款资料")]),
                            _c(
                              "el-divider",
                              { attrs: { "content-position": "right" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.customerDownload(
                                          item.fileFirmDto.firmId,
                                          "assetsCustomerRe"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 下载 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        item.assetsDto
                          ? _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("file-list", {
                                  attrs: {
                                    "list-type": "picture-card",
                                    files: item.assetsDto.receivableFile,
                                    "handle-preview": function(val) {
                                      return _vm.previewFile(val, "other")
                                    },
                                    props: { name: "typeName", src: "url" }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c("span", [_vm._v("资产资料/发票资料")]),
                            _c(
                              "el-divider",
                              {
                                attrs: { "content-position": "right" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "assetsCustomerInvoice"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v(" 下载 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        item.assetsDto
                          ? _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("file-list", {
                                  attrs: {
                                    "list-type": "picture-card",
                                    files: item.assetsDto.invoiceFile,
                                    "handle-preview": function(val) {
                                      return _vm.previewFile(val, "invoice")
                                    },
                                    props: { name: "typeName", src: "url" }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { span: 24 }
                      },
                      [
                        _c("span", [_vm._v("授信资料")]),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "creditCustomer"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 下载 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("授信资料/合同签署资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "creditCustomerCon"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.creditDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.creditDto.contractFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "concat")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("授信资料/附件资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "creditCustomerEnc"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.creditDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.creditDto.enclosureFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "credit")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("授信资料/基础合同资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "creditCustomerBasic"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.creditDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.creditDto.basicContractFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "other")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("授信资料/发票资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "creditCustomerInvoice"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.creditDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.creditDto.invoiceFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "invoice")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { span: 24 }
                      },
                      [
                        _c("span", [_vm._v("用信资料")]),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "useCustomer"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 下载 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/合同签署资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerContract"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.contractFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "concat")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/附件资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerEnclosure"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.enclosureFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "use")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/应收账款资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerRe"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.reFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "other")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/应收发票资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerReInvoice"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.reInvoiceFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "invoice")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/放款凭证")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerVoucher"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.loanFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "use")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("用信资料/收款凭证")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "useCustomerCollection"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.useDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.useDto.collectionFile,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "use")
                                        },
                                        props: { name: "typeName", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { span: 24 }
                      },
                      [
                        _c("span", [_vm._v("付款资料")]),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.customerDownload(
                                      item.fileFirmDto.firmId,
                                      "repaymentCustomer"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 下载 ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { span: 24 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("付款资料/付款凭证资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "repaymentCustomerCo"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.repaymentDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.repaymentDto.pay,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "repay")
                                        },
                                        props: { name: "typeCode", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c("span", [_vm._v("付款资料/收款凭证资料")]),
                                _c(
                                  "el-divider",
                                  { attrs: { "content-position": "right" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.customerDownload(
                                              item.fileFirmDto.firmId,
                                              "repaymentCustomerPay"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            item.repaymentDto
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("file-list", {
                                      attrs: {
                                        "list-type": "picture-card",
                                        files: item.repaymentDto.co,
                                        "handle-preview": function(val) {
                                          return _vm.previewFile(val, "repay")
                                        },
                                        props: { name: "typeCode", src: "url" }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }