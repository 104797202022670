<template>
  <ics-page-inner :title="titleName">
    <template slot="btn-inner">
      <div class="btn-inner">
        <el-button type="primary" @click="download('OneKey')">
          一键下载
        </el-button>
      </div>
    </template>
    <template>
      <el-col :span="24">
        <span>企业资料/企业资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('customerFirm')">
            下载
          </el-button>
        </el-divider>
      </el-col>
      <el-col v-if="customerDetail.fileFirmDto" :span="24">
        <file-list
          list-type="picture-card"
          :files="customerDetail.fileFirmDto.firmList"
          :handle-preview="(val)=> previewFile(val, 'firm')"
          :props="{name: 'typeName', src: 'url'}"
        />
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>授信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('creditCustomer')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCustomerCon')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.creditDto.contractFile"
              :handle-preview="(val)=> previewFile(val, 'concat')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCustomerEnc')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.creditDto.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'credit')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/基础合同资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCustomerBasic')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.creditDto.basicContractFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>授信资料/发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('creditCustomerInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.creditDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.creditDto.invoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>用信资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('useCustomer')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/合同签署资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerContract')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.contractFile"
              :handle-preview="(val)=> previewFile(val, 'concat')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/附件资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerEnclosure')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.enclosureFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收账款资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerRe')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.reFile"
              :handle-preview="(val)=> previewFile(val, 'other')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/应收发票资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerReInvoice')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.reInvoiceFile"
              :handle-preview="(val)=> previewFile(val, 'invoice')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/放款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerVoucher')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.loanFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>用信资料/收款凭证</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('useCustomerCollection')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.useDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.useDto.collectionFile"
              :handle-preview="(val)=> previewFile(val, 'use')"
              :props="{name: 'typeName', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-top: 5px;">
        <span>付款资料</span>
        <el-divider content-position="right">
          <el-button type="primary" @click="download('repaymentCustomer')">
            下载
          </el-button>
        </el-divider>
        <el-col :span="24" style="margin-top: 5px;">
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/付款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCustomerCo')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.repaymentDto.pay"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
          <el-col :span="24" style="margin-top: 5px;">
            <span>付款资料/收款凭证资料</span>
            <el-divider content-position="right">
              <el-button type="primary" @click="download('repaymentCustomerPay')">
                下载
              </el-button>
            </el-divider>
          </el-col>
          <el-col v-if="customerDetail.repaymentDto" :span="24">
            <file-list
              list-type="picture-card"
              :files="customerDetail.repaymentDto.co"
              :handle-preview="(val)=> previewFile(val, 'repay')"
              :props="{name: 'typeCode', src: 'url'}"
            />
          </el-col>
        </el-col>
      </el-col>
    </template>
  </ics-page-inner>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsCustomerInfoInner',
  components: {},
  mixins: [routeEnterMixin],
  props: {
    titleName: {
      type: String,
      default: ''
    },
    firmId: {
      type: [Number,String],
      default: ''
    },
    code: {
      type: [Number,String],
      default: ''
    },
    customerInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      customerDetail: {}
    }
  },
  watch: {
    customerInfo: {
      handler (val) {
        if (val) {
          console.log(val)
          this.customerDetail = val
        }
      },
      immediate: true
    },
  },
  created() {},
  methods: {
    previewFile (file, bucket) {
      this.utils.downloadP(bucket, file.url)
    },
    download (type) {
      if (type === 'OneKey') {
        this.api.system.archives.customerDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'customerFirm') {
        this.api.system.archives.customerFirmDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomer') {
        this.api.system.archives.assetsCustomerDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerBasic') {
        this.api.system.archives.assetsCustomerDownloadBasic(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerRe') {
        this.api.system.archives.assetsCustomerDownloadRe(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'assetsCustomerInvoice') {
        this.api.system.archives.assetsCustomerDownloadInvoice(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomer') {
        this.api.system.archives.creditCustomerDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerCon') {
        this.api.system.archives.creditCustomerConDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerEnc') {
        this.api.system.archives.creditCustomerEncDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerBasic') {
        this.api.system.archives.creditCustomerBasicDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'creditCustomerInvoice') {
        this.api.system.archives.creditCustomerInvoiceDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomer') {
        this.api.system.archives.useCustomerDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerContract') {
        this.api.system.archives.useCustomerContractDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerEnclosure') {
        this.api.system.archives.useCustomerEnclosureDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerRe') {
        this.api.system.archives.useCustomerReDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerReInvoice') {
        this.api.system.archives.useCustomerReInvoiceDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerVoucher') {
        this.api.system.archives.useCustomerVoucherDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'useCustomerCollection') {
        this.api.system.archives.useCustomerCollectionDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomer') {
        this.api.system.archives.repaymentCustomerDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomerCo') {
        this.api.system.archives.repaymentCustomerCoDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else if (type === 'repaymentCustomerPay') {
        this.api.system.archives.repaymentCustomerPayDownload(this.firmId).then(result => {
          this.utils.downloadP(result.data.bucket, result.data.objectKey)
        }).finally(() => {
        })
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
